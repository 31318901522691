import React from "react"

import ListedPost from "./ListedPost"
import PostsContainer from "../../global/atoms/PostsContainer"

const PostsList = ({ posts }) => (
  <PostsContainer>
    {posts &&
      posts.map(post => <ListedPost key={`post-${post.id}`} data={post} />)}
  </PostsContainer>
)

export default PostsList
