const getRangedSrcSet = (src, size) => {
  // Jeśli tekst jest pusty, zwracamy null
  if (!src) return null;

  // Jeśli tekst zawiera .svg, zwracamy go bez zmian
  if (src.includes('.svg')) return src;

  // Zamiana starego URL-a na nowy format z zachowaniem rozmiaru
  const updatedSrc = src.replace(/https?:\/\/[^ ]+?\/app\/uploads\/([\w\/\.-]+?)(\s\d+w)?(\s|$)/g, (match, filename, size, space) => {
    return `https://super-tajny-headless-cms.bravenew.agency/app/compressx-nextgen/uploads/${filename}.webp${size || ''}${space || ''}`;
  });

  // Dopasowanie wszystkich URL-i wraz z szerokościami w formacie "URL 519w"
  const regex = /(https?:\/\/[^ ]+\/app\/compressx-nextgen\/uploads\/[\w\/\.-]+?\.(jpg|png|webp))\s(\d+w)/g;
  const matches = [...updatedSrc.matchAll(regex)].map(match => ({
    fullSrc: `${match[1]} ${match[3]}`,  // Zapisujemy cały URL z szerokością "URL 519w"
    width: parseInt(match[3].replace('w', ''), 10),  // Usuwamy "w" i konwertujemy na liczbę
  }));

  // Znajdź maksymalną szerokość obrazu w `matches`
  const maxOriginalWidth = Math.max(...matches.map(item => item.width));

  // Warunek: jeśli maksymalna szerokość jest poniżej 600px, zwróć wszystkie URL-e
  if (maxOriginalWidth < 600) {
    return matches.map(item => item.fullSrc).join(', ');
  }

  // Zakresy szerokości dla różnych wartości `size`
  const sizeRanges = {
    small: 768,
    medium: 1280,
    big: 1536,
    original: Infinity, // dla `original` pobieramy wszystkie rozmiary
  };

  // Jeśli `size` to `original`, zwróć wszystkie URL-e
  if (size === 'original' || !size) return matches.map(item => item.fullSrc).join(', ');

  // Pobieramy maksymalną szerokość dla podanego `size`
  const maxWidth = sizeRanges[size];

  // Filtrowanie URL-i po szerokości do maksymalnej wartości
  const filteredUrls = matches
    .filter(({ width }) => width <= maxWidth)
    .map(({ fullSrc }) => fullSrc);

  // Jeśli mamy dopasowania w wybranym zakresie, zwracamy je wszystkie
  if (filteredUrls.length > 0) {
    return filteredUrls.join(', ');
  } else
    return updatedSrc;
};

export default getRangedSrcSet;
