import React from "react"
import styled from "styled-components"

import Arrow from "../../../assets/images/svg/right-arrow.svg"

import { betweenD, maxD } from "../../../assets/styles/helpers/devices"

import { isLinkAnAnchor } from "../../../assets/styles/helpers/common"
import { ClassicLink, UnderlinedLink } from "../common/links"
import { SecondaryTitle } from "../common/text"

import SiteLanguage from "../../../helpers/siteLanguage"

const Container = styled.li`
  --padding-vertical: 2rem;
  --padding-horizontal: 2rem;

  position: relative;

  margin-bottom: 50px;

  @media ${maxD.laptop} {
    width: 100%;
  }

  @media ${betweenD.tabletLaptop} {
    display: flex;
    align-items: center;
  }

  @media ${maxD.tabletL} {
    margin-bottom: 30px;
  }
`

const ImagesContainer = styled.figure`
  position: relative;
  padding-bottom: var(--image-height, 100%);
  overflow: hidden;

  @media ${betweenD.tabletLaptop} {
    width: 50%;
    flex-shrink: 0;
    --image-height: 50%;
  }
`

const Content = styled.article`
  padding: var(--padding-vertical) var(--padding-horizontal);

  @media ${maxD.mobileL} {
    padding: var(--padding-vertical) 0;
  }
`

const Description = styled.p`
  padding: 0 0 1rem;

  font-weight: 400;

  @media ${maxD.tabletL} {
    padding: 0rem 0 1rem;
  }
`

const PostContent = ({
  children,
  link,
  title,
  excerpt,
  containerStyle,
  buttonLabel = SiteLanguage() === "en" ? "Read more" : "Czytaj",
}) => (
  <Container style={containerStyle}>
    {children && <ImagesContainer>{children}</ImagesContainer>}
    <Content>
      <SecondaryTitle>
        <ClassicLink to={link} anchor={isLinkAnAnchor(link)}>
          {title}
        </ClassicLink>
      </SecondaryTitle>
      <Description dangerouslySetInnerHTML={{ __html: excerpt }}></Description>
      <UnderlinedLink to={link} anchor={isLinkAnAnchor(link)}>
        {buttonLabel}
        <Arrow />
      </UnderlinedLink>
    </Content>
  </Container>
)

export default PostContent
