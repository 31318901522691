import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

import { MainTitle } from "../../global/common/text"
import {
  BannerContainer,
  BannerWrapper,
  LineWrapperWithBottomSpace,
} from "../../global/common/containers"
import { FadedGreenBoxTitle } from "../../global/header/common"
import { SocialMedia } from "../../global/SocialMedia"

import Nav from "../../global/nav/Nav"

import SiteLanguage from "../../../helpers/siteLanguage"

const BlogBannerWrapper = styled(BannerWrapper).attrs({
  as: LineWrapperWithBottomSpace,
})``

const BlogListingTitle = styled(MainTitle)`
  max-width: 650px;

  @media ${minD.tabletL} {
    padding-right: 70px;
  }

  @media ${maxD.tabletL} {
    padding-bottom: 3rem;
  }
`

const SocialMediaWrapper = styled.figure`
  width: min(400px, var(--wrapper-width));

  @media ${maxD.tablet} {
    padding-left: 20px;
  }
`

const SocialMediaTitle = styled(FadedGreenBoxTitle)`
  @media ${minD.laptopL} {
    margin-right: 250px;
  }
`

const BlogListingHeader = ({ pageContext, title }) => (
  <BannerContainer
    style={{
      "--laptop-padding": "180px 0 0",
    }}
  >
    <Nav pageContext={pageContext} />
    <BlogBannerWrapper relative flex alignItems="fe" spaceBetween>
      <BlogListingTitle as="h1" dangerouslySetInnerHTML={{ __html: title }} />
      <SocialMediaWrapper>
        <SocialMediaTitle>
          {
            SiteLanguage() === "en" ?
              "You can also follow us on social media:"
            :
              "Śledź nas w mediach społecznościowych"
          }
        </SocialMediaTitle>
        <SocialMedia style={{ justifyContent: "flex-end" }} />
      </SocialMediaWrapper>
    </BlogBannerWrapper>
  </BannerContainer>
)

export default BlogListingHeader
