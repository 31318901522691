const getImageBySrcSetAndSize = (src, size) => {
  // Jeśli tekst jest pusty, zwracamy null
  if (!src) return null;

  // Jeśli tekst zawiera .svg, zwracamy go bez zmian
  if (src.includes('.svg')) return src;

  // Zamiana starego URL-a na nowy format
  const updatedSrc = src.replace(/https?:\/\/[^ ]+?\/app\/uploads\/([\w\/\.-]+?)(\s\d+w)?(\s|$)/g, (match, filename, _, space) => {
    return `https://super-tajny-headless-cms.bravenew.agency/app/compressx-nextgen/uploads/${filename}.webp${space || ''}`;
  });

  // Wyrażenie regularne do wyciągania URL-i i ich rozmiarów
  const regex = /https?:\/\/[^ ]+\/app\/compressx-nextgen\/uploads\/[\w\/\.-]+\.(jpg|webp)/g;
  const matches = [...updatedSrc.matchAll(regex)];

  // Przechowujemy znalezione URL-e oraz ich szerokości
  const urls = [];
  matches.forEach(match => {
    const url = match[0].replace(/\s\d+w$/, ''); // Usunięcie rozmiaru na końcu
    const widthMatch = url.match(/-(\d+)x\d+\./); // Dopasowanie szerokości z formatu "-300x200."
    const width = widthMatch ? parseInt(widthMatch[1]) : null;
    if (width) urls.push({ url, width });
  });

  // Jeśli nie znaleziono żadnych URL-i, zwracamy null
  if (urls.length === 0) return null;

  // Jeśli nie podano parametru size, zwracamy oryginał
  if (!size) return urls[urls.length - 1].url; // Ostatni URL to oryginał

  let selectedUrl;
  const originalUrl = urls[urls.length - 1].url;

  // Logika wyboru URL w zależności od rozmiaru, z obsługą najbliższego większego
  switch (size) {
    case 'small':
      selectedUrl = urls.find(({ width }) => width > 0 && width <= 768)?.url ||
                    urls.find(({ width }) => width > 768)?.url ||
                    originalUrl;
      break;
    case 'medium':
      selectedUrl = urls.find(({ width }) => width > 768 && width <= 1280)?.url ||
                    urls.find(({ width }) => width > 1280)?.url ||
                    originalUrl;
      break;
    case 'big':
      selectedUrl = urls.find(({ width }) => width > 1280 && width <= 1536)?.url ||
                    urls.find(({ width }) => width > 1536)?.url ||
                    originalUrl;
      break;
    case 'original':
      selectedUrl = originalUrl; // Ostatni URL to oryginał
      break;
    default:
      selectedUrl = originalUrl;
  }

  return selectedUrl; // Zwracamy wybrany URL
};

export default getImageBySrcSetAndSize;
