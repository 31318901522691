import styled from "styled-components"
import { minD } from "../../../assets/styles/helpers/devices"

import { UnstyledList } from "../common/essentials"

const PostsContainer = styled(UnstyledList)`
  display: grid;

  @media ${minD.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default PostsContainer
